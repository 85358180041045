<template>
  <div>
    <span style="display: none">{{ equipment }}</span>
    <div class="height-100">
      <border-box :src="src">
        <span slot="title" class="title border-title">操作台</span>
        <div slot="box-container" class="box-container">
          <template>
            <div class="top-left">
              <el-descriptions
                :column="2"
                :colon="false"
                :labelStyle="{
                  'line-height': '45px',
                  color: '#1ED0F8',
                  width: '40px',
                  display: 'flex',
                  'justify-content': 'flex-end'
                }"
              >
                <el-descriptions-item label="类型">
                  <el-select
                    v-model="typevalue"
                    placeholder="选择类型"
                    :popper-append-to-body="false"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in typeoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="时间">
                  <el-date-picker
                    v-model="date"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    class="w-100"
                    @change="dateChange"
                  >
                  </el-date-picker>
                </el-descriptions-item>
                <el-descriptions-item label="数据">
                  <el-select
                    v-model="datavalue"
                    placeholder="选择数据"
                    :popper-append-to-body="false"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in dataoptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="算法">
                  <el-select
                    v-model="algType"
                    placeholder="选择算法"
                    :popper-append-to-body="false"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in algoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <div class="top-right" @click="handleCalc">
              <img src="@/assets/img/calc.png" width="100%" height="100%" />
              <p>计算</p>
            </div>
          </template>

          <template>
            <div class="left">
              <p class="tip-title">分析结果</p>
              <div class="p14">
                <div class="grid primary-box">
                  <!-- TODO 写死了的 -->
                  <p>单位蠕动量</p>
                  <p></p>
                  <p>蠕动量阈值</p>
                  <p></p>
                  <p>设备当前状态</p>
                  <p></p>
                  <p>未来一周状态</p>
                  <p></p>
                </div>
              </div>
            </div>

            <div class="chart-box">
              <gaugeSpeedChart
                chartId="可靠度"
                :chartOption="progressOption"
                style="width: 440px; height: 340px"
                :chartData="[progressData]"
              />
            </div>
          </template>
          <template>
            <p class="tip-title">结果图</p>
            <div class="">
              <lineChart
                chartId="结果"
                :keys="resultKeys"
                :chartData="resultData"
                :chartOption="resultOption"
                chartType="line"
                style="width: 100%; height: 250px"
              />
            </div>
          </template>
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import lineChart from '@/views/components/lineChart.vue'
import gaugeSpeedChart from '@/views/components/gaugeSpeedChart.vue'

import {getBreakListByDate,getBreakResultById,getBreakByHistory,getSlipListByDate,getSlipResultById,getSlipByHistory} from '@/api/diagnose'

export default {
  components: { lineChart, gaugeSpeedChart },
  data() {
    return {
      src: require('@/assets/img/console.png'),
      date: '', // 时间
      typevalue: '', // 类型
      typeoptions: [
        {
          value: 1,
          label: '制动距离'
        },
        {
          value: 2,
          label: '滑移距离'
        }
      ],
      datavalue: '', // 数据
      dataoptions: [], // 数据数组

      algType: '', // 算法
      algoptions: [
        {
          value: 1,
          label: '算法一'
        },
        {
          value: 2,
          label: '算法二'
        },
        {
          value: 3,
          label: '算法三'
        }
      ],
      progressData: 0,
      progressOption: {
        color: ['#999']
      },
      resultKeys:[],
      resultData:[],
      resultOption:{
        lineWidth:2,
        color:['rgba(0,255,62,1)','rgba(0,255,62,0)']
      }
    }
  },
  computed: {
    equipment() {
      const equipment = this.$store.state.equipment
      if (equipment) this.init(equipment)
      return equipment
    }
  },
  methods: {
    // 初始化日期
    getDate() {
      const now = new Date()
      this.date = now.toLocaleDateString().replace(/\//g, '-')
      this.typevalue = this.typeoptions[0].value
      this.algType = this.algoptions[0].value
    },
    async init(equipment) {
      if (!this.date) return;
      const getDataByHistory = this.typevalue == '1' ? getBreakByHistory :getSlipByHistory
      const result = await getDataByHistory(equipment) // 结果预测
      this.resultKeys = result.time
      this.resultData = result.data
      
      const getDataByDate = this.typevalue == '1' ? getBreakListByDate : getSlipListByDate
      this.dataoptions = await getDataByDate(equipment,{date:this.date}) // 获取数据
      if (!this.dataoptions.length) {
        this.datavalue = ''
        this.progressData = 0
        this.$parent.sourceData = {}
        this.$notify.warning('暂无可用数据，请重新选择日期！')
        return
      };
    },

    // 日期变化
    async dateChange(){
      const getDataByDate = this.typevalue == '1' ? getBreakListByDate : getSlipListByDate
      this.dataoptions = await getDataByDate(this.equipment,{date:this.date}) // 获取数据
      if (!this.dataoptions.length) this.datavalue = '';
      // else this.datavalue = this.dataoptions[0]
    },

    // 手动点击计算
    async handleCalc() {
      const getDataById = this.typevalue == '1' ? getBreakResultById :getSlipResultById
      const sourceData = await getDataById({rid:this.datavalue,did:this.equipment}, {algType:this.algType}) // 获取图表
      this.progressData = sourceData.score *100 || 0
      this.$parent.sourceData = sourceData;
    },

  }
}
</script>

<style lang="scss" scoped>
@import '../../index.scss';
.box-container {
  .top-right {
    background-image: url('../../../../../assets/img/square-bg.png');
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
}
.primary-box {
  // width: 50%;
  margin-bottom: 8px;
  p {
    border: 1px solid #003aa3;
    line-height: 50px;
    color: #63e2ff;
    text-align: center;
  }
}
// .grid-box{
//   display: inline-block;
// }
.left {
  width: 50%;
  display: inline-block;
}
.chart-box {
  width: 50%;
  float: right;
}
</style>
