<template>
  <div class="height-100">
    <div class="height-50">
      <border-box :src="srcTop">
        <span slot="title" class="title border-title">电流数据</span>
        <el-select
          v-model="direction"
          size="small"
          :popper-append-to-body="false"
          slot="title"
          class="direction-select"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <p class="btn-group">
          <img src="@/assets/img/add.png" alt="" />
          <img src="@/assets/img/add.png" alt="" />
          <img src="@/assets/img/add.png" alt="" />
          <img src="@/assets/img/add.png" alt="" />
        </p>
        <div slot="box-container">
          <lineChart
            chartId="电流数据"
            :keys="dataRawXX"
            :chartData="dataRawYY"
            :chartOption="chartOption"
            chartType="line"
            style="width: 100%; height: 300px"
            ref="table"
          />
        </div>
      </border-box>
    </div>

    <div class="height-50">
      <border-box :src="srcTop">
        <span slot="title" class="title border-title"
          >负序电流权值变化趋势</span
        >
        <div slot="box-container">
          <lineChart
            chartId="负序电流权值变化趋势 "
            :keys="dataWeightXX"
            :chartData="dataWeightYY"
            :chartOption="chartOption1"
            chartType="line"
            style="width: 100%; height: 300px"
            ref="table"
          />
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import lineChart from '@/views/components/lineChart.vue'
export default {
  components: { lineChart },
  data() {
    return {
      direction: 'X',
      options: [
        {
          label: 'X向',
          value: 'X'
        },
        {
          label: 'Y向',
          value: 'Y'
        }
      ],
      srcTop: require('@/assets/img/line-chart.png'),
      dataRawXX: [],
      dataRawYY: [],
      dataWeightXX: [],
      dataWeightYY: [],
      chartOption: {
        lineWidth: 2,
        color: ['rgba(0, 198, 253,1)', 'rgba(0, 198, 253,0.5)'],
        areaColor: [],
        dataZoom: 'slider'
      },
      chartOption1: {
        lineWidth: 2,
        color: ['rgba(0,0,0,0)'],
        areaColor: ['rgba(0, 198, 253,1)', 'rgba(255,255,255,1)']
      }
    }
  },
  watch: {
    '$store.state.dataRawXX'(newVal, oldVal) {
      this.dataRawXX = newVal
    },
    '$store.state.dataRawYY'(newVal, oldVal) {
      this.dataRawYY = newVal
    },
    '$store.state.dataWeightXX'(newVal, oldVal) {
      this.dataWeightXX = newVal
    },
    '$store.state.dataWeightYY'(newVal, oldVal) {
      this.dataWeightYY = newVal
    }
  },
  created() {
    this.dataRawXX = this.$store.state.dataRawXX
    this.dataRawYY = this.$store.state.dataRawYY
    this.dataWeightXX = this.$store.state.dataWeightXX
    this.dataWeightYY = this.$store.state.dataWeightYY
  }
}
</script>
<style scoped lang="scss">
.title-box {
  width: 100%;
  height: 50px;
  overflow: hidden;
  span {
    margin-right: 16px;
  }
}
.btn-group {
  float: right;
  line-height: 50px;
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  img + img {
    margin-left: 16px;
  }
}
</style>
