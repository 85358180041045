<template>
  <div>
    <span style="display:none;">{{ equipment }}</span>
    <div class="height-100">
      <border-box :src="src">
        <span slot="title" class="title border-title">操作台</span>
        <div slot="box-container" class="box-container">
          <template>
            <div class="top-left">
              <el-descriptions
                :column="2"
                :colon="false"
                :labelStyle="{
                  'line-height': '45px',
                  color: '#1ED0F8',
                  width: '40px',
                  display: 'flex',
                  'justify-content': 'flex-end'
                }"
              >
                <el-descriptions-item label="时间">
                  <el-date-picker
                    v-model="date"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    class="w-100"
                  >
                  </el-date-picker>
                </el-descriptions-item>
                <el-descriptions-item label="数据">
                  <el-select
                    v-model="dataItem"
                    placeholder="选择数据"
                    :popper-append-to-body="false"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in dataOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="算法">
                  <el-select
                    v-model="algType"
                    placeholder="选择算法"
                    :popper-append-to-body="false"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in algoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <div class="top-right" @click="handleCalc">
              <img src="@/assets/img/calc.png" width="100%" height="100%" />
              <p>计算</p>
            </div>
          </template>

          <template>
            <p class="tip-title">振动信号特征量</p>
            <div class="p14">
              <div class="grid primary-box">
                <p class="first-line">特征值</p>
                <p class="first-line">X轴</p>
                <p class="first-line">Y轴</p>
                <p class="first-line">Z轴</p>
                <template v-for="(item,i) in dataX">
                  <p :key="4*i+0">{{dataX[i].name}}</p>
                  <p :key="4*i+1">{{dataX[i].value}}</p>
                  <p :key="4*i+2">{{dataY[i].value}}</p>
                  <p :key="4*i+3">{{dataZ[i].value}}</p>
                </template>
              </div>
            </div>
          </template>
          <div>
            <p class="tip-title">健康度</p>
            <div class="progress-bar-chart">
              <progressBarChart
                :chartData="[health]"
                :chartOption="healthOption"
                chartId="健康度"
                style="width: 580px; height: 31px"
                class="chart"
              />
              <p>{{ health }}<span class="unit">%</span></p>
            </div>
          </div>
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import progressBarChart from '@/views/components/progressBarChart.vue'

import {getVibrationData, getVibrationDateData,getVibrationResultData} from '@/api/diagnose'

export default {
  components: { progressBarChart },
  data() {
    return {
      src: require('@/assets/img/console.png'),
      date: '',
      health: 0,
      healthOption: {
        lineWidth: 2,
        color: ['rgba(0, 198, 253,1)', 'rgba(0, 198, 253,0.1)'],
        areaColor: ['rgba(0, 198, 253,0.5)']
      },
      dataItem: '', // 数据
      algType: '', // 算法
      dataOptions: [], // 数据数组
      algoptions: [
        {
          value: 1,
          label: '算法一'
        },
        {
          value: 2,
          label: '算法二'
        },
        {
          value: 3,
          label: '算法三'
        }
      ],
      dataX: [], //X数据
      dataY: [], //Y数据
      dataZ: [] //Z数据
    }
  },
  computed: {
    equipment() {
      const equipment = this.$store.state.equipment
      if (equipment) this.init(equipment)
      return equipment
    }
  },
  methods: {
    // 初始化日期
    getDate() {
      const now = new Date()
      this.date = now.toLocaleDateString().replace(/\//g, '-')

    },
    // 页面初始化数据
    async init(equipment) {
      if(!this.date) return ;
      this.dataOptions = await getVibrationDateData(equipment, { date: this.date }) // 获取数据列表
      if (!this.dataOptions.length) {
        // 未获取数据列表，不进行下一步，清除已有数据
        this.dataItem = ''
        this.$parent.sourceData = {}
        this.dataCab = []
        this.dataDoor = []
        this.health = 0
        this.$notify.warning('暂无可用数据，请重新选择日期！')
        return;
      }
    },
    // 手动点击计算
    async handleCalc() {
      let leftData = await getVibrationData({ did: this.equipment, rid: this.dataItem }) // 获取左侧chart数据
      this.$parent.sourceData = leftData
      // 获取右侧数据
      let rightData = await getVibrationResultData(
        { did: this.equipment, rid: this.dataItem },
        { algType: this.algType }
      )
      this.dataX = rightData.dataX
      this.dataY = rightData.dataY
      this.dataZ = rightData.dataZ
      this.health = this.randomNum(80,90)
    },
    randomNum(Min, Max) {
        var Range = Max - Min;
        var Rand = Math.random();
        if (Math.round(Rand * Range) == 0) {
          return Min + 1;
        } else if (Math.round(Rand * Max) == Max) {
          index++;
          return Max - 1;
        } else {
          var num = Min + Math.round(Rand * Range) - 1;
          return num;
        }
      }
  }
}
</script>

<style lang="scss" scoped>
@import '../../index.scss';
.box-container {
  .top-right {
    background-image: url('../../../../../assets/img/square-bg.png');
  }
  .tip-title{
    margin-top: 0;
    margin-bottom: 0;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 48px;
}

.primary-box {
  min-height: 430px;
  margin-bottom: 8px;
  border: 1px solid #003aa3;
  .bg-purple {
    border: 1px solid #003aa3;
    color: #63e2ff;
    text-align: center;
    padding: 8px 0;
    background-color: rgba($color: #276f96, $alpha: 0.5);
  }
  #feature {
    border: 1px solid #003aa3;
    color: #63e2ff;
    text-align: center;
    padding: 12px 0;
  }
  .first-line{
    background-color: rgba(0,58,163,0.5);
  }
  p {
    border: 1px solid #003aa3;
    line-height: 45px;
    color: #63e2ff;
    text-align: center;
  }
  ::v-deep .el-input__inner {
    background-color: transparent;
    border-color: transparent;
    height: 40px;
    margin-top: 2px;
    text-align: center;
  }
}
.progress-bar-chart .chart {
  background-image: url('../../../../../assets/img/rectangle-bg.png');
}
</style>
