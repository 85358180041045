<template>
  <div class="safety-content">
    <vibrationLeft class="safety-content-left" ref="left" />
    <vibrationRight class="safety-content-right" ref="right" />
  </div>
</template>

<script>
import vibrationLeft from './vibrationLeft.vue'
import vibrationRight from './vibrationRight.vue'
export default {
  components: { vibrationLeft, vibrationRight },
  data() {
    return {
      sourceData:{}
    }
  },
  watch: {
    sourceData(val){
      this.$refs['left'].sourceData = val
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../index.scss';
</style>
