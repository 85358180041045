<template>
  <div class="safety-content">
    <vibrationLeft class="safety-content-left" />
    <vibrationRight class="safety-content-right" />
  </div>
</template>

<script>
import vibrationLeft from './vibrationLeft.vue'
import vibrationRight from './vibrationRight.vue'
export default {
  components: { vibrationLeft, vibrationRight }
}
</script>

<style scoped lang="scss">
  @import '../../index.scss';
</style>
