<template>
  <div>
    <div
      class="speed-chart"
      :id="chartId"
      :style="{ height: '100%', width: '100%' }"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  props: {
    chartData: {
      style: Array,
      default: () => {
        return []
      }
    },
    top: {
      style: Number,
      default: () => {
        return 100
      }
    },
    chartOption: {
      stype: Object,
      default: () => {
        return {}
      }
    },
    chartId: {
      style: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartData: function () {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      var value = this.chartData
      var color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
        {
          offset: 0,
          color: '#41D7F3'
        },
        {
          offset: 1,
          color: '#3D9FFF'
        }
      ])
      const options = {
        grid: {
          left: '0',
          top: '0',
          bottom: '0',
          right: '0'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 225,
            endAngle: -45,
            min: 0,
            max: 100,
            radius: '67%',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#999'
              }
            },
            axisTick: {
              length: 5,
              lineStyle: {
                color: 'rgba(60,189,252,1)'
              }
            },
            detail: {
              show: true,
              offsetCenter: [0, '80%'],
              formatter: function (value) {
                return value
              },
              fontSize: 100,
              fontWeight: 800
            },

            splitLine: {
              length: 20,
              lineStyle: {
                color: '#999'
              }
            },
            axisLabel: {
              color: '#3AAAC8',
              fontSize: 20
            },
            pointer: {
              show: true,
              width: 8,
              length: '70%',
              background: '#1ED0F8'
            },
            itemStyle: {
              color: '#5470C6'
            },
            markPoint: {
              animation: false,
              silent: true,
              data: [
                {
                  x: '50%',
                  y: '50%',
                  symbol: 'circle',
                  symbolSize: 10,
                  itemStyle: {
                    color: '#5470C6'
                  }
                },
                {
                  x: '50%',
                  y: '50%',
                  symbol: 'circle',
                  symbolSize: 15,
                  itemStyle: {
                    color: '#fff'
                  }
                }
              ]
            },
            data: [
              {
                value: value
              }
            ]
          },
          {
            type: 'gauge',
            radius: '75%',
            startAngle: 225,
            endAngle: -45,
            min: 0,
            max: 100,
            title: {
              show: false
            },
            detail: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 18,
                color: [
                  [value / 100, '#5470C6'],
                  [1, 'rgba(210,219,238,0.3)']
                ]
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            }
          }
        ]
      }
      this.chart.setOption(options, true)
    },
    initChart() {
      const target = document.getElementById(this.chartId)
      this.chart = echarts.init(target)
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>
