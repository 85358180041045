<template>
  <div class="height-100">
    <div class="height-50">
      <border-box :src="srcTop">
        <span slot="title" class="title border-title">轿厢音频数据</span>

        <div slot="box-container">
          <lineChart
            chartId="轿厢音频数据"
            :keys="soundKeys"
            :chartData="soundData"
            :chartOption="soundOption"
            chartType="line"
            style="width: 100%; height: 300px"
            ref="table"
          />
        </div>
      </border-box>
    </div>
    <div class="height-50">
      <border-box :src="srcTop">
        <span slot="title" class="title border-title">傅里叶变化数据</span>
        <div slot="box-container">
          <lineChart
            chartId="傅里叶变化数据"
            :keys="fourierKeys"
            :chartData="fourierData"
            :chartOption="fourierOption"
            chartType="line"
            style="width: 100%; height: 300px"
            ref="table"
          />
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import lineChart from '@/views/components/lineChart.vue'

// import lineChart from '@/views/components/vibrationLineChart.vue'
export default {
  components: { lineChart },
  data() {
    return {
      sourceData: {},
      srcTop: require('@/assets/img/line-chart.png'),
      soundKeys: [],
      soundData: [],
      soundOption: {
        lineWidth: 2,
        color: ['rgba(0, 198, 253,1)', 'rgba(0, 198, 253,0)'],
        dataZoom: 'slider'
      },
      fourierKeys: [],
      fourierData: [],
      fourierOption: {
        lineWidth: 2,
        color: ['rgba(0, 198, 253,1)'],
        areaColor: ['rgba(255,255,255,1)','rgba(0, 198, 253,1)'],
        dataZoom: 'slider'
      }
    }
  },
  watch: {
    'sourceData':{
      handler(val) {
      if (Object.keys(val).length) {
        // 轿厢音频数据
        this.soundKeys = []
        let i = 0
        for (i; i < val.rawData.length; i++) {
          this.soundKeys.push(i)
        }
        this.soundData = val.rawData

        // 傅里叶变化数据
        this.fourierKeys = val.freqData
        this.fourierData = val.ampData
      } else {
        this.soundKeys = []
        this.soundData = []
        this.fourierKeys = []
        this.fourierData = []
      }
    },
    immediate:true,
    deep:true
  }
  }
}
</script>
