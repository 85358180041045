<template>
  <component :is="signal"></component>
</template>

<script>
import distance from './distance/index'
import vibration from './vibration/index'
import electric from './electric/index'
export default {
  name: 'index',
  computed: {
    equipment() {
      return this.$store.state.equipment
    },
    tab() {
      return this.$store.state.tab
    }
  },
  data() {
    return { signal: '' }
  },
  components: { distance, vibration, electric },
  watch: {
    // 监听父级页面切换tab,这里切换页面
    $route: {
      handler(route) {
        this.signal = route.query.signal
        if (!this.signal) {
          this.signal = 'distance'
          this.$router.push({
            path: route.path,
            query: {
              equipment: this.equipment,
              tab: this.tab,
              signal: 'distance'
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },

  // 销毁前清除signal
  beforeDestroy() {
    this.$store.commit('REMOVE_SIGNAL')
  }
}
</script>
