import request from '@/utils/request.js'

//声音
export function getAudioDateData(did, data) {
  return request({
    url: '/api/v1/devices/' + did + '/audio-records',
    method: 'get',
    params: data
  })
}

export function getAudioData({did,rid}) {
  return request({
    url: '/api/v1/devices/' + did + '/audio-records/'+rid,
    method: 'get'
  })
}

export function getAudioResultData({did, rid}, value) {
  return request({
    url: '/api/v1/devices/' + did + '/audio-records/' + rid + '/result',
    method: 'get',
    params: value
  })
}

//振动

export function getVibrationData({did, rid}) {
  return request({
    url: '/api/v1/devices/' + did + '/vibration-records/' + rid,
    method: 'get'
  })
}

export function getVibrationDateData(did, data) {
  return request({
    url: '/api/v1/devices/' + did + '/vibration-records/',
    method: 'get',
    params: data
  })
}

export function getVibrationResultData({did, rid}, value) {
  return request({
    url: '/api/v1/devices/' + did + '/vibration-records/' + rid + '/result',
    method: 'get',
    params: value
  })
}

//门电机
export function getDoorListByDate(did, data) {
  return request({
    url: '/api/v1/devices/' + did + '/door-records/',
    method: 'get',
    params: data
  })
}
export function getDoorResultById(did, rid, value) {
  return request({
    url: '/api/v1/devices/' + did + '/door-records/' + rid,
    method: 'get',
    params: value
  })
}

//曳引机

export function getTractionListByDate(did, data) {
  return request({
    url: '/api/v1/devices/' + did + '/traction-records/',
    method: 'get',
    params: data
  })
}
export function getTractionResultById(did, rid, value) {
  return request({
    url: '/api/v1/devices/' + did + '/traction-records/' + rid,
    method: 'get',
    params: value
  })
}

//制动距离

export function getBreakListByDate(did, data) {
  return request({
    url: '/api/v1/devices/' + did + '/break-records/',
    method: 'get',
    params: data
  })
}

export function getBreakResultById({did, rid}, value) {
  return request({
    url: '/api/v1/devices/' + did + '/break-records/' + rid,
    method: 'get',
    params: value
  })
}

//制动距离阈值图请求

export function getBreakByHistory(did) {
  return request({
    url: '/api/v1/devices/' + did + '/break-records/history',
    method: 'get'
  })
}

//滑移距离

export function getSlipListByDate(did, data) {
  return request({
    url: '/api/v1/devices/' + did + '/slip-records/',
    method: 'get',
    params: data
  })
}
export function getSlipResultById({did, rid}, value) {
  return request({
    url: '/api/v1/devices/' + did + '/slip-records/' + rid,
    method: 'get',
    params: value
  })
}

//滑移距离阈值图请求

export function getSlipByHistory(did) {
  return request({
    url: '/api/v1/devices/' + did + '/slip-records/history',
    method: 'get'
  })
}
