<template>
  <div class="chart">
    <div
      class="line-chart"
      :id="chartId"
      style="height: 100%; width: 100%"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  name: 'lineChart',
  /**
   * @params chartData 图表数据
   * @params keys 图表x轴
   * @params chartType 图表类型（line、bar）
   * @params chartId id
   * @params chartOption 个性化配置
   */
  props: {
    chartData: {
      style: Array,
      default: []
    },
    keys: {
      style: Array,
      default: []
    },
    chartType: {
      style: String,
      default: () => {
        return ''
      }
    },
    chartId: {
      style: String,
      default: ''
    },
    chartOption: {
      style: Object,
      default: {}
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartData: function (val, valOld) {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      let series = {
        name: this.chartId,
        data: this.chartData,
        type: this.chartType,
        smooth: true,
        symbolSize: 1,
        // stack: 'Total',
        lineStyle: {
          width: this.chartOption.lineWidth || 1
        },
        itemStyle: {
          normal: {
            color: (params) => {
              let color = ''
              if (params.seriesType === 'bar')
                color =
                  this.chartOption.color[params.dataIndex % 2] ||
                  params.color + '99'
              else color = this.chartOption.color[0]
              return color
            }
          }
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: this.chartOption.areaColor&&this.chartOption.areaColor[0] || this.chartOption.color[0] || 'rgba(0, 198, 253,1)',
              opacity: 1
            },
            {
              offset: 1,
              color: this.chartOption.areaColor&&this.chartOption.areaColor[1] ||  this.chartOption.color[1]||'rgba(0,0,0,0)',
              opacity: 0
            }
          ])
        },
        showSymbol: true, // 数据标点
        emphasis: {
          focus: 'series',
          label: {
            show: true,
            formatter: ({ value }) => {
              var arr = ['{value|' + value + '}']
              return arr.join('\n')
            },
            rich: {
              value: {
                // width: 85,
                padding: [0, 6],
                height: 24,
                borderRadius: 3,
                backgroundColor: '#FCAD14',
                align: 'center',
                color: '#FFFFFF',
                fontSize: 14,
                lineHeight: 24
              }
            }
          },
          itemStyle: {
            color: '#fff',
            borderColor: '#FCAD14',
            borderWidth: 5
          }
        }
      }
      const options = {
        color: this.chartOption.color,
        barWidth: 20,
        grid: {
          top: 20,
          right: 20,
          bottom:
            this.chartOption.grid?.bottom || this.chartOption.dataZoom
              ? 36
              : 20,
          left: 20,
          containLabel: true
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
            zoomLock: !this.chartOption.dataZoom
          },
          {
            type:'slider',
            start: 0,
            end: 100,
            showDetail: false,
            dataBackground:{
              lineStyle:{
                color: this.chartOption.color[0]
              },
              areaStyle: {
                color: this.chartOption.color[0]
              },
            },
            fillerColor: 'rgba(30, 208, 248,0.3)',
            borderColor: 'rgba(30, 208, 248,1)',
            height: 20,
            bottom: 0,
            show: !!this.chartOption.dataZoom
          }
        ],
        tooltip: {
          trigger: 'axis',
          show: this.chartOption.tooltip?.show || true,
          formatter: () => {
            return ''
          }
        },
        // legend: {
        //   color: '#000',
        //   data: [this.chartId]
        // },
        xAxis: [
          {
            type: 'category',
            data: this.keys,
            boundaryGap: true,
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#0DA6E1',
                textAlign: 'right'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            splitArea: {
              show: false
            },
            min: this.chartOption.yAxis?.min ,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#0DA6E1',
                textAlign: 'right'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#0DA6E1'
              }
            }
          }
        ],
        series: series
      }
      this.chart.setOption(options, true)
    },
    initChart() {
      const target = document.getElementById(this.chartId)
      this.chart = echarts.init(target)
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>
<style>
.chart {
  overflow: hidden;
}
</style>
