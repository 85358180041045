<template>
  <div class="chart">
    <div
      class="line-chart"
      :id="chartId"
      style="height: 100%; width: 100%"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  name: 'lineChart',
  /**
   * @params chartData 图表数据
   * @params keys 图表x轴
   * @params chartType 图表类型（line、bar）
   * @params chartId id
   * @params chartOption 个性化配置
   */
  props: {
    chartData: {
      style: Array,
      default: []
    },
    keys: {
      style: Array,
      default: []
    },
    chartType: {
      style: String,
      default: () => {
        return ''
      }
    },
    chartId: {
      style: String,
      default: ''
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartData: function (val, valOld) {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      const options = {
        legend: {
          top: 14,
          left: 'center',
          selectedMode: 'single',
          itemGap: 26,
          textStyle: {
            color: '#1ED0F8'
          }
        },
        grid: {
          top: 46,
          right: 20,
          bottom: 36,
          left: 20,
          containLabel: true
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            type: 'slider',
            start: 0,
            end: 100,
            showDetail: false,
            fillerColor: 'rgba(30, 208, 248,0.3)',
            borderColor: 'rgba(30, 208, 248,1)',
            height: 20,
            bottom: 0
          }
        ],
        tooltip: {
          trigger: 'axis',
          formatter: () => {
            return ''
          }
        },
        xAxis: {
          type: 'category',
          data: this.keys,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#1ED0F8'
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          // name: 'mm/s²',
          nameGap: 10,
          nameTextStyle: {
            color: '#1ED0F8'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#1ED0F8'
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'line',
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#fff',
                  opacity: 1
                },
                {
                  offset: 0.5,
                  color: '#10C950',
                  opacity: 0.5
                },
                {
                  offset: 1,
                  color: '#fff',
                  opacity: 1
                }
              ])
            },
            itemStyle: {
              normal: {
                color: '#32D4F9'
              }
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: ({ value }) => {
                  var arr = ['{value|' + value + '}']
                  return arr.join('\n')
                },
                rich: {
                  value: {
                    // width: 85,
                    padding: [0, 6],
                    height: 24,
                    borderRadius: 3,
                    backgroundColor: '#FCAD14',
                    align: 'center',
                    color: '#FFFFFF',
                    fontSize: 14,
                    lineHeight: 24
                  }
                }
              },
              itemStyle: {
                color: '#fff',
                borderColor: '#FCAD14',
                borderWidth: 5
              }
            },
            data: this.chartData
          }
        ]
      }
      this.chart.setOption(options, true)
    },
    initChart() {
      const target = document.getElementById(this.chartId)
      this.chart = echarts.init(target)
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>
<style>
.chart {
  overflow: hidden;
}
</style>
