<template>
  <div class="height-100">
    <div class="height-50">
      <border-box :src="srcTop">
        <span slot="title" class="title border-title">钢丝绳位移变化图</span>
        <div slot="box-container">
          <lineChart
            chartId="钢丝绳位移 "
            :keys="keys"
            :chartData="wireRopeData"
            :chartOption="wireRopeOption"
            chartType="line"
            style="width: 100%; height: 300px"
            ref="table"
          />
        </div>
      </border-box>
    </div>

    <div class="height-50">
      <border-box :src="srcTop">
        <span slot="title" class="title border-title">速度变化图</span>
        <div slot="box-container">
          <lineChart
            chartId="速度"
            :keys="keys"
            :chartData="speedData"
            :chartOption="speedOption"
            chartType="line"
            style="width: 100%; height: 300px"
            ref="table"
          />
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import lineChart from '@/views/components/lineChart.vue'

export default {
  components: { lineChart },
  data() {
    return {
      srcTop: require('@/assets/img/line-chart.png'),
      sourceData: {},
      keys: [],
      wireRopeData: [],
      wireRopeOption: {
        lineWidth: 2,
        color: ['rgba(0, 198, 253,1)', 'rgba(0, 198, 253,0)'],
        dataZoom: 'slider'
      },
      speedData: [],
      speedOption: {
        lineWidth: 2,
        color:['rgba(0,255,62,1)','rgba(0,255,62,0)'],
        dataZoom: 'slider'
      }
    }
  },
  watch: {
    sourceData: {
      handler(val) {
        if (Object.keys(val).length) {
          // 钢丝绳位移变化图
          this.keys = []
          let i = 0
          for (i; i < val.rawData.length; i++) {
            this.keys.push(i)
          }
          this.wireRopeData = val.rawData

          // 速度变化图 
          this.speedData = val.speedData
        } else {
          this.keys = []
          this.wireRopeData = []
          this.speedData = []
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
