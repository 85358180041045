<template>
  <div>
    <div
      class="pie-chart"
      :id="chartId"
      :style="{ height: '100%', width: '100%' }"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  /**
   * @param chartData {array} 进度条数据
   * @param top {number} 进度条长度
   * @param chartOption {object} 个性化配置参数
   * @param chartId {string} 图表id，也是图表title
   *
   */
  props: {
    chartData: {
      style: Array,
      default: () => {
        return []
      }
    },
    top: {
      style: Number,
      default: () => {
        return 100
      }
    },
    chartOption: {
      stype: Object,
      default: () => {
        return {}
      }
    },
    chartId: {
      style: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartData: function () {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      const options = {
        grid: {
          top: 5,
          left: 5,
          right: 5,
          bottom: 5,
          containLabel: true
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          offset: 10,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          // 进度条
          {
            type: 'bar',
            barWidth: 21,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color:
                      (this.chartOption.color && this.chartOption.color[0]) ||
                      '#2C50FF'
                  },
                  {
                    offset: 1,
                    color:
                      (this.chartOption.colora && this.chartOption.color[1]) ||
                      '#3DFB17'
                  }
                ])
              }
            },
            z: 9999,
            data: this.chartData
          },
          // 背景
          {
            type: 'bar',
            barWidth: 21,
            barGap: '-100%',
            itemStyle: {
              normal: {
                color: '#02225A'
              }
            },
            data: [this.top]
          },
          // 分隔
          {
            type: 'pictorialBar',
            itemStyle: {
              normal: {
                color: '#02225A'
              }
            },
            symbolRepeat: 'fixed',
            symbolMargin: 2,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [4, 21],
            symbolPosition: 'start',
            symbolOffset: [-4, 0],
            data: this.chartData,
            width: 25,
            z: 0,
            zlevel: 1
          }
        ]
      }
      this.chart.setOption(options, true)
    },
    initChart() {
      const target = document.getElementById(this.chartId)
      this.chart = echarts.init(target)
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>
