<template>
  <div>
    <div class="height-100">
      <border-box :src="src">
        <span slot="title" class="title border-title">操作台</span>
        <div slot="box-container" class="box-container">
          <template>
            <div class="top-left">
              <el-descriptions
                :column="2"
                :colon="false"
                :labelStyle="{
                  'line-height': '45px',
                  color: '#1ED0F8',
                  width: '40px',
                  display: 'flex',
                  'justify-content': 'flex-end'
                }"
              >
                <el-descriptions-item label="类型">
                  <el-select
                    v-model="typevalue"
                    placeholder="选择类型"
                    :popper-append-to-body="false"
                    @change="getCurrentTypeDataList"
                  >
                    <el-option
                      v-for="item in typeoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="时间">
                  <el-date-picker
                    v-model="datevalue"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    @change="handleChangeTime"
                  >
                  </el-date-picker>
                </el-descriptions-item>
                <el-descriptions-item label="数据">
                  <el-select
                    v-model="datavalue"
                    placeholder="选择数据"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="item in dataoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="算法">
                  <el-select
                    v-model="algvalue"
                    placeholder="选择算法"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="item in algoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <div class="top-right" @click="startCompute">
              <img src="@/assets/img/calc.png" width="100%" height="100%" />
              <p>计算</p>
            </div>
          </template>

          <template>
            <p class="tip-title">最小权值</p>
            <div class="p14 progress-bar-chart1">
              <progressChart1
                :chartData="chartData"
                chartId="最小权值"
                style="width: 580px; height: 31px"
                class="chart"
              />
              <p>{{ chartData[0] }}<span class="unit">%</span></p>
            </div>
          </template>
          <template>
            <p class="tip-title">平均值</p>
            <div class="p14 progress-bar-chart1">
              <progressChart1
                :chartData="chartData1"
                chartId="平均值"
                style="width: 580px; height: 31px"
                class="chart"
              />
              <p>{{ chartData[0] }}<span class="unit">%</span></p>
            </div>
          </template>
          <template>
            <p class="tip-title">最大权值</p>
            <div class="p14 progress-bar-chart1">
              <progressChart1
                :chartData="chartData2"
                chartId="最大权值"
                style="width: 580px; height: 31px"
                class="chart"
              />
              <p>{{ chartData[0] }}<span class="unit">%</span></p>
            </div>
          </template>

          <div class="group">
            <div
              class="group-chart p14"
              :id="chartId"
              style="height: 100%; width: 100%; margin-top: 58px"
            ></div>
          </div>
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import progressChart1 from '@/views/components/progressBarChart.vue'
import echarts from 'echarts'
import 'echarts-liquidfill'

import {
  getDoorListByDate,
  getDoorResultById,
  getTractionListByDate,
  getTractionResultById
} from '@/api/diagnose.js'
export default {
  components: { progressChart1 },
  data() {
    return {
      src: require('@/assets/img/console.png'),
      a: '',
      chartData: [],
      chartData1: [],
      chartData2: [],
      chartOption: {
        lineWidth: 2,
        color: ['rgba(0, 198, 253,1)', 'rgba(0, 198, 253,0.1)'],
        areaColor: ['rgba(0, 198, 253,0.5)']
      },
      chartId: 'group',
      chart: null,

      typevalue: '', // 类型
      datevalue: '', // 时间
      datavalue: '', // 数据
      algvalue: '', // 算法
      typeoptions: [
        {
          value: 1,
          label: '门电机'
        },
        {
          value: 2,
          label: '曳引电机'
        }
      ],
      dataoptions: [], // 数据数组
      algoptions: [
        {
          value: 1,
          label: '算法一'
        },
        {
          value: 2,
          label: '算法二'
        },
        {
          value: 3,
          label: '算法三'
        }
      ],
      dataRawXX: [],
      dataRawYY: [],
      dataWeightXX: [],
      dataWeightYY: [],
      dataThresholdXX: '0'
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartData: function (val, valOld) {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      var value = this.dataThresholdXX
      const options = {
        title: [
          {
            // text: '本年收缴率',
            x: '50%',
            y: '80%',
            textStyle: {
              fontSize: 14,
              fontWeight: '100',
              color: '#5dc3ea',
              lineHeight: 16,
              textAlign: 'center'
            }
          }
        ],
        graphic: [
          {
            type: 'group',
            left: 'center',
            top: '25%',
            children: [
              {
                type: 'text',
                z: 100,
                left: '40',
                top: 'middle',
                style: {
                  fill: '#aab2fa',
                  text: '健康度',
                  font: '20px Microsoft YaHei'
                }
              }
            ]
          }
        ],
        series: [
          {
            type: 'liquidFill',
            radius: '100%',
            center: ['50%', '50%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#2ca3e2'
                  },
                  {
                    offset: 1,
                    color: '#40ECA8'
                  }
                ],
                globalCoord: false
              }
            ],
            data: [value], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: 'RGBA(51, 66, 127, 0.7)'
            },
            label: {
              normal: {
                textStyle: {
                  fontSize: 20,
                  color: '#fff'
                }
              }
            },
            outline: {
              show: false,
              borderDistance: 10,
              itemStyle: {
                borderWidth: 2,
                borderColor: '#112165'
              }
            }
          }
        ]
      }
      this.chart.setOption(options, true)
    },
    initChart() {
      const target = document.getElementById(this.chartId)
      this.chart = echarts.init(target)
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    },
    //获取门电机电流数据
    getDoorDataList() {
      const currentDevice = this.$route.query.equipment
      getDoorListByDate(currentDevice, { date: this.datevalue }).then((res) => {
        for (var i = 0; i < res.length; i++) {
          this.dataoptions.push({ value: res[i], label: res[i] })
        }
      })
    },
    //获取曳引机电流数据
    getTractionDataList() {
      const currentDevice = this.$route.query.equipment
      getTractionListByDate(currentDevice, { date: this.datevalue }).then(
        (res) => {
          for (var i = 0; i < res.length; i++) {
            this.dataoptions.push({ value: res[i], label: res[i] })
          }
        }
      )
    },
    //获取当前电流类型的数据集合
    getCurrentTypeDataList() {
      if (this.datevalue) {
        if (this.typevalue == 1) {
          this.getDoorDataList()
          this.datavalue = ''
        } else {
          this.getTractionDataList()
          this.datavalue = ''
        }
      }
    },
    handleChangeTime(val) {
      if (val) {
        this.getCurrentTypeDataList()
      } else {
        this.dataoptions = []
        this.algvalue = ''
        this.datavalue = ''
      }
    },
    //获取数据
    startCompute() {
      if (this.datevalue === 'null') {
        this.$message('请选择日期！')
        return
      } else if (this.datavalue == '') {
        this.$message('数据不能为空！')
      }
      if (this.typevalue == 1) {
        this.getDoorResult()
      } else {
        this.getTractionResult()
      }
    },

    getDoorResult() {
      const currentDevice = this.$route.query.equipment
      getDoorResultById(currentDevice, this.datavalue, {
        algType: this.algvalue
      })
        .then((res) => {
          var temp = JSON.parse(JSON.stringify(res))
          console.log(temp)

          // console.log(temp)
          for (var i = 0; i < temp.rowData.length; i++) {
            this.dataRawXX.push(i.toString())
          }
          //将原始数据深拷贝保存
          this.dataRawYY = temp.rowData
          //保存权重
          for (var i = 0; i < temp.weightData.length; i++) {
            this.dataWeightXX.push(i.toString())
          }
          this.dataWeightYY = temp.weightData
          //保存最小，平均，最大权重
          this.chartData = [temp.weights[0] * 100]
          this.chartData1 = [temp.weights[1] * 100]
          this.chartData2 = [temp.weights[2] * 100]

          //保存阈值
          this.dataThresholdXX = temp.threshold

          this.$store.commit('setDataRawXX', this.dataRawXX)
          this.$store.commit('setDataRawYY', this.dataRawYY)
          this.$store.commit('setDataWeightXX', this.dataWeightXX)
          this.$store.commit('setDataWeightYY', this.dataWeightYY)
        })
        .catch((err) => {})
    },
    getTractionResult() {
      const currentDevice = this.$route.query.equipment
      getTractionResultById(currentDevice, this.datavalue, {
        algType: this.algvalue
      })
        .then((res) => {
          console.log(res.data)
          var temp = JSON.parse(JSON.stringify(res.data))
          // console.log(temp)
          for (var i = 0; i < temp.rowData.length; i++) {
            this.dataRawXX.push(i.toString())
          }
          //将原始数据深拷贝保存
          this.dataRawYY = temp.rowData
          //保存权重
          for (var i = 0; i < temp.weightData.length; i++) {
            this.dataWeightXX.push(i.toString())
          }
          this.dataWeightYY = temp.weightData
          //保存最小，平均，最大权重

          this.chartData = [temp.weights[0]]
          this.chartData1 = [temp.weights[1]]
          this.chartData2 = [temp.weights[2]]

          //保存阈值
          this.dataThresholdXX = temp.threshold
          this.$store.commit('setDataRawXX', this.dataRawXX)
          this.$store.commit('setDataRawYY', this.dataRawYY)
          this.$store.commit('setDataWeightXX', this.dataWeightXX)
          this.$store.commit('setDataWeightYY', this.dataWeightYY)
        })
        .catch((err) => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../index.scss';
.box-container {
  .top-right {
    background-image: url('../../../../../assets/img/square-bg.png');
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 48px;
}

.primary-box {
  padding: 13px;
  margin-bottom: 8px;
  p {
    border: 1px solid #003aa3;
    line-height: 45px;
    color: #63e2ff;
    text-align: center;
  }
  ::v-deep .el-input__inner {
    background-color: transparent;
    border-color: transparent;
    height: 40px;
    margin-top: 2px;
    text-align: center;
  }
}

.group {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.progress-bar-chart .chart {
  background-image: url('../../../../../assets/img/rectangle-bg.png');
}
</style>
