<template>
  <div class="safety-content">
    <stateLeft class="safety-content-left" ref="left" />
    <stateRight class="safety-content-right" ref="tight" />
  </div>
</template>

<script>
import stateLeft from './stateLeft.vue'
import stateRight from './stateRight.vue'
export default {
  components: { stateLeft, stateRight },
  data() {
    return {
      sourceData:{}
    }
  },
  watch: {
    sourceData(val){
      this.$refs['left'].sourceData = val
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../index.scss';
</style>
