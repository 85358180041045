<template>
  <div class="height-100">
    <div class="height-50">
      <border-box :src="srcTop">
        <span slot="title" class="title border-title">轿厢振动数据</span>
        <el-select
          slot="title"
          class="direction-select"
          v-model="direction"
          size="small"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div slot="box-container">
          <vibrationLineChart
            chartId="轿厢振动数据 "
            :keys="vibrarionKeys"
            :chartData="vibrarionData"
            chartType="line"
            style="width: 100%; height: 300px"
          />
        </div>
      </border-box>
    </div>

    <div class="height-50">
      <border-box :src="srcTop">
        <span slot="title" class="title border-title">傅里叶变化数据</span>
        <div slot="box-container">
          <lineChart
            chartId="傅里叶变化数据 "
            :keys="fourierKeys"
            :chartData="fourierData"
            :chartOption="fourierOption"
            chartType="line"
            style="width: 100%; height: 300px"
          />
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import lineChart from '@/views/components/lineChart.vue'
import vibrationLineChart from '@/views/components/vibrationLineChart.vue'
export default {
  components: { lineChart, vibrationLineChart },
  data() {
    return {
      srcTop: require('@/assets/img/line-chart.png'),
      direction: 'X',
      options: [
        {
          value: 'X',
          label: 'X向'
        },
        {
          value: 'Y',
          label: 'Y向'
        },
        {
          value: 'Z',
          label: 'Z向'
        }
      ],
      sourceData: {},
      vibrarionKeys: [],
      vibrarionData: [],
      fourierKeys: [],
      fourierData: [],
      fourierOption: {
        lineWidth: 2,
        color: ['rgba(0,0,0,0)'],
        areaColor: ['rgba(255,255,255,1)', 'rgba(0, 198, 253,1)'],
        dataZoom: 'slider'
      }
    }
  },
  methods: {
    setChart() {
      if (Object.keys(this.sourceData).length) {
        // 轿厢振动数据
        this.vibrarionKeys = []
        let i = 0
        for (i; i < this.sourceData['rawData' + this.direction].length; i++) {
          this.vibrarionKeys.push(i)
        }
        this.vibrarionData = this.sourceData['rawData' + this.direction]

        // 傅里叶变化数据
        this.fourierKeys = this.sourceData['freqData' + this.direction]
        this.fourierData = this.sourceData['ampData' + this.direction]
      } else {
        this.vibrarionKeys = []
        this.vibrarionData = []
        this.fourierKeys = []
        this.fourierData = []
      }
    }
  },
  watch: {
    sourceData: {
      handler(val) {
        this.setChart()
      },
      immediate: true,
      deep: true
    },
    direction(val) {
      this.setChart()
    }
  }
}
</script>
<style scoped lang="scss">
.direction-select {
  margin-left: 16px;
}
.title-box {
  width: 100%;
  height: 50px;
  // overflow: hidden;
  .border-title {
    display: inline-block;
  }
  span {
    margin-right: 16px;
  }
}
.btn-group {
  float: right;
  line-height: 50px;
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  img + img {
    margin-left: 16px;
  }
}
</style>
