<template>
  <div>
    <span style="display: none">{{ equipment }}</span>
    <div class="height-100">
      <border-box :src="src">
        <span slot="title" class="title border-title">操作台</span>
        <div slot="box-container" class="box-container">
          <template>
            <div class="top-left">
              <el-descriptions
                :column="2"
                :colon="false"
                :labelStyle="{
                  'line-height': '45px',
                  color: '#1ED0F8',
                  width: '40px',
                  display: 'flex',
                  'justify-content': 'flex-end'
                }"
              >
                <el-descriptions-item label="时间">
                  <el-date-picker
                    v-model="date"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    append-to-body
                    @change="dateChange"
                    class="w-100"
                  >
                  </el-date-picker>
                </el-descriptions-item>
                <el-descriptions-item label="数据">
                  <el-select
                    v-model="dataItem"
                    placeholder="选择数据"
                    :popper-append-to-body="false"
                    class="w-100"
                  >
                    <el-option
                      v-for="(item, i) in dataOptions"
                      :key="i"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="算法">
                  <el-select
                    v-model="algType"
                    placeholder="选择算法"
                    :popper-append-to-body="false"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in algoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <div class="top-right" @click="handleCalc">
              <img src="@/assets/img/calc.png" width="100%" height="100%" />
              <p>计算</p>
            </div>
          </template>
          <template>
            <p class="tip-title">开门关门声音特征性</p>
            <div class="p14">
              <div class="grid primary-box">
                <template v-for="(item, i) in dataDoor">
                  <p :key="2 * i">{{ item.name }}</p>
                  <p :key="2 * i + 1">{{ item.value }}</p>
                </template>
              </div>
            </div>
          </template>
          <template>
            <p class="tip-title">轿厢声音特征性</p>
            <div class="p14">
              <div class="grid primary-box">
                <template v-for="(item, i) in dataCab">
                  <p :key="2 * i">{{ item.name }}</p>
                  <p :key="2 * i + 1">{{ item.value }}</p>
                </template>
              </div>
            </div>
          </template>
          <template>
            <p class="tip-title">健康度</p>
            <div class="progress-bar-chart">
              <progressChart1
                :chartData="[health]"
                chartId="健康度"
                style="width: 580px; height: 31px"
                class="chart"
              />
              <p>{{ health }}<span class="unit">%</span></p>
            </div>
          </template>
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import progressChart1 from '@/views/components/progressBarChart.vue'

import {
  getAudioDateData,
  getAudioData,
  getAudioResultData
} from '@/api/diagnose'
export default {
  components: { progressChart1 },
  data() {
    return {
      src: require('@/assets/img/console.png'),
      chartData: [0],
      algoptions: [
        {
          value: 1,
          label: '算法一'
        },
        {
          value: 2,
          label: '算法二'
        },
        {
          value: 3,
          label: '算法三'
        }
      ],
      algType: '', // 算法
      dataOptions: [],
      dataItem: '',

      date: '', // 日期
      dataCab: [],
      dataDoor: [],
      health: 0
    }
  },
  computed: {
    equipment() {
      const equipment = this.$store.state.equipment
      if (equipment) this.init(equipment)
      return equipment
    }
  },
  methods: {
    // 初始化日期
    getDate() {
      const now = new Date()
      this.date = now.toLocaleDateString().replace(/\//g, '-')
    },
    // 页面初始化数据
    async init(equipment) {
      if (!this.date) return ;
      this.dataOptions = await getAudioDateData(equipment, { date: this.date }) // 获取数据列表
      if (!this.dataOptions.length) {
        // 未获取数据列表，不进行下一步，清除已有数据
        this.dataItem = ''
        this.$parent.sourceData = {}
        this.dataCab = []
        this.dataDoor = []
        this.health = 0
        this.$notify.warning('暂无可用数据，请重新选择日期！')
        return
      }
    },
    // 手动点击计算
    async handleCalc() {
      let leftData = await getAudioData({
        did: this.equipment,
        rid: this.dataItem
      }) // 获取左侧chart数据
      this.$parent.sourceData = leftData
      // 获取右侧数据
      let rightData = await getAudioResultData(
        { did: this.equipment, rid: this.dataItem },
        { algType: this.algType }
      )
      this.dataCab = rightData.dataCab
      this.dataDoor = rightData.dataDoor
      this.health = rightData.score
    },
    // 日期变化，重新请求数据
    async dateChange() {
    }
  }
}
</script>

<style lang="scss">
body .el-picker-panel {
  color: #1ed0f8;
  background-color: #021946;
  border-color: #1ed0f8;
  .el-picker-panel__icon-btn,
  .el-date-picker__header-label {
    color: #1ed0f8;
  }
  .el-date-table th {
    color: #1ed0f8;
    border-color: #1ed0f8;
  }
  .el-date-table td.current:not(.disabled) span {
    background-color: rgba(0, 198, 253, 0.8);
  }
}
</style>

<style lang="scss" scoped>
@import '../index.scss';

.tip-title {
  margin-top: 0;
  margin-bottom: 0;
}
.box-container {
  .top-right {
    background-image: url('../../../../assets/img/square-bg.png');
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 45px;
}
.primary-box {
  width: 100%;
  min-height: 190px;
  // padding: 13px;
  margin-bottom: 8px;
  border: 1px solid #003aa3;
  p {
    border: 1px solid #003aa3;
    line-height: 45px;
    color: #63e2ff;
    text-align: center;
  }
  ::v-deep .el-input__inner {
    background-color: transparent;
    border-color: transparent;
    height: 40px;
    margin-top: 2px;
    text-align: center;
  }
}
.progress-bar-chart .chart {
  background-image: url('../../../../assets/img/rectangle-bg.png');
}
</style>
