<template>
  <div class="wrapper-safety">
    <div class="safety-inner">
      <ul>
        <li
          :class="{ 'tab-active': tab === 'sound' }"
          @click="handleChangeTab('sound')"
        >
          基于声音信号的性能评估
          <p></p>
        </li>
        <li
          :class="{ 'tab-active': tab === 'state' }"
          @click="handleChangeTab('state')"
        >
          基于状态检测信号的性能评估
          <p></p>
        </li>
        <li class="right-btn-group" v-show="tab === 'state'">
          <div
            class="btn"
            :class="{ 'signal-active': signal === 'distance' }"
            @click="handleChange('distance')"
          >
            距离（制动&移滑）
          </div>
          <div
            class="btn"
            :class="{ 'signal-active': signal === 'vibration' }"
            @click="handleChange('vibration')"
          >
            振动信号
          </div>
          <div
            class="btn"
            @click="handleChange('electric')"
            :class="{ 'signal-active': signal === 'electric' }"
          >
            电流信号
          </div>
        </li>
      </ul>

      <component :is="tab" style="width: 100%; height: 100%"></component>
    </div>
  </div>
</template>

<script>
import sound from './sound/index.vue'
import state from './state/index.vue'
export default {
  components: {
    sound,
    state
  },
  computed: {
    equipment() {
      return this.$store.state.equipment
    }
  },
  data() {
    return {
      tab: '',
      signal: 'distance',
      query: {}
    }
  },
  created() {
    const query = this.$route.query
    this.getTab()
  },
  methods: {
    // 切换tab
    handleChangeTab(tab) {
      this.$store.commit('SET_TAB', tab)
      this.tab = tab
      let query = {
        equipment: this.equipment,
        tab: tab
      }
      if (tab === 'state') {
        this.signal = 'distance'
        query.signal = this.signal
      }
      this.$router.push({
        path: this.$route.path,
        query: query
      })
    },
    // 切换检测信号
    handleChange(signal) {
      this.$store.commit('SET_SIGNAL', signal)
      this.signal = signal
      this.$router.push({
        path: this.$route.path,
        query: {
          equipment: this.equipment,
          tab: this.tab,
          signal: signal
        }
      })
    },
    // 页面初始化路由
    getTab() {
      console.log('safe query getTab', this.$route.query)
      const query = this.$route.query
      this.tab = query.tab
      this.signal = query.signal
      this.$store.commit('SET_TAB', this.tab)
      if (!this.tab) {
        this.tab = 'sound'
        this.$store.commit('SET_TAB', this.tab)
        this.$router.push({
          path: this.$route.path,
          query: {
            equipment: this.equipment,
            tab: this.tab
          }
        })
      }
    }
  },
  // 销毁前清除tab和signal
  beforeDestroy() {
    this.$store.commit('REMOVE_TAB')
  }
}
</script>

<style lang="scss" scoped>
.wrapper-safety {
  width: 100%;
  height: 88%;

  padding-right: 46px;
  /* padding-bottom: 46px; */
  padding-left: 46px;
  .safety-inner {
    width: 100%;
    height: 88%;

    ul {
      display: flex;
      align-items: center;
      height: 60px;
      li {
        margin-left: 2.66%;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;

        p {
          width: 50px;
          height: 3px;
          margin: 6px auto;
        }
        &:hover {
          color: #1ed0f8;
        }
      }
      .tab-active {
        color: #1ed0f8;
        p {
          background-color: #1ed0f8;
        }
      }
    }

    .right-btn-group {
      flex: 1;
      text-align: right;
      .btn {
        float: right;
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0);
        background-color: #062666;
        padding: 0 20px;
        margin-left: 16px;
        opacity: 0.8;
        color: #1ed0f8;
        font-size: 16px;
      }
      .btn:hover,
      .signal-active {
        border-color: #1ed0f8;
        color: #ffffff;
      }
    }
  }
}
</style>
