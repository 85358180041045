<template>
  <div class="safety-content">
    <soundLeft class="safety-content-left" ref="left" />
    <soundRight class="safety-content-right" ref="right" />
  </div>
</template>

<script>
import soundLeft from './soundLeft.vue'
import soundRight from './soundRight.vue'

export default {
  components: { soundLeft, soundRight },
  data() {
    return {
      sourceData:{}
    }
  },
  watch: {
    sourceData(val){
      this.$refs['left'].sourceData = val
    }
  }
}
</script>

<style scoped lang="scss">
.safety-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .safety-content-left {
    flex: 0 1 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .safety-content-right {
    flex: 0 1 45%;
    height: 100%;
  }
}
</style>
